import React from "react";

import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo/seo";
import Form from "../sections/delete-user/Form";
import Hero from "../sections/delete-user/Hero";
import Body from "../sections/delete-user/Body";

const ContactUsPage = () => {
  return (
    <PageWrapper headerDark footerDark>
      <Seo title="Delete account" desc="How to Delete Your Flop App Account." />
      <Hero />
      <Body />
      <Form />
    </PageWrapper>
  );
};

export default ContactUsPage;
