import React, { useState } from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";

import { Title, Button, Box, Text, Input, Select } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const isFormValid = name && email && message;

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { ok } = await fetch(`${process.env.GATSBY_CONTACT_US_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({
          contactName: name,
          contactEmail: email,
          subject: 'Delete account',
          // We manually setup the origin
          contactOrigin: "FLOP_APP_WEBSITE",
          content: message,
        }),
      });
      if (ok) {
        setIsSuccessModalVisible(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box id="form" px={[2, null]} py={[null, null, null, null, 2]}>
        <Row className="align-items-center justify-content-center">
          <Col md="10" lg="8" xl="4">
            <form onSubmit={(e) => e.preventDefault()}>
              <Box mb={3}>
                <Input
                  type="text"
                  placeholder="Your name"
                  required
                  onChange={({ target: { value } }) => setName(value)}
                  value={name}
                />
              </Box>
              <Box mb={3}>
                <Input
                  type="email"
                  placeholder="Your Email address"
                  required
                  onChange={({ target: { value } }) => setEmail(value)}
                  value={email}
                />
              </Box>
              <Box mb={3}>
                <Input
                  type="text"
                  as="textarea"
                  placeholder="Write your message"
                  rows={4}
                  name="message"
                  onChange={({ target: { value } }) => setMessage(value)}
                  value={message}
                  required
                />
              </Box>
              <Box mt={4}>
                <Button
                  bg={isFormValid ? "#4D8DCC" : "#D2D9DF"}
                  style={{
                    ...((!isFormValid || isSubmitting) && {
                      pointerEvents: "none",
                    }),
                  }}
                  onClick={onSubmit}
                  type="submit"
                  width="100%"
                >
                  {isSubmitting ? (
                    <Spinner animation="border" />
                  ) : (
                    <FlopFormattedMessageWrapper id="contact-us.form.cta" />
                  )}
                </Button>
              </Box>
            </form>
          </Col>
        </Row>
      </Box>
      <Modal
        show={isSuccessModalVisible}
        onHide={() => setIsSuccessModalVisible(false)}
        centered
      >
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
          <Title variant="small-section">Contact</Title>
          <Text>
            Your message has been sent to our team, thanks for your interest{" "}
            <b>{name}</b>.
          </Text>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={16} py={2}>
          <Text
            color="#4D8DCC"
            onClick={() => setIsSuccessModalVisible(false)}
            style={{ cursor: "pointer" }}
          >
            OK
          </Text>
        </Box>
      </Modal>
    </>
  );
};

export default Form;
