import React from "react";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 5]}>
          <Col>
            <Row className="justify-content-left">
              <Col lg="12" className="order-lg-1">
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <Title color="light" variant="hero">
                      How to Delete Your Flop App Account
                    </Title>
                    <Text color="light" mb={4}>
                      We're sorry to hear that you're considering deleting your Flop App account. We strive to provide a seamless and enjoyable poker experience for our users. If there's something specific you're unhappy with or if you have suggestions for improvement, we'd love to hear from you at <a href="mailto:support@firstlandofpoker.com"><span  style={{color: 'white'}}>support@firstlandofpoker.com</span></a>.
                    </Text>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default Hero;
