import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Section, Text, Box} from "../../components/Core";
import step1Png from "../../assets/image/flop/delete-user/step1.png";
import step2Png from "../../assets/image/flop/delete-user/step2.png";
import step3Png from "../../assets/image/flop/delete-user/step3.png";
import step4Png from "../../assets/image/flop/delete-user/step4.png";
import step5Png from "../../assets/image/flop/delete-user/step5.png";
import step6Png from "../../assets/image/flop/delete-user/step6.png";
const Body = () => {
    return (
        <>
            <Section className="position-relative pt-5 pb-5">
                <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 5]}>
                    <Text color="dark" mb={4}>
                        However, if you still wish to proceed with the deletion of your account, we completely understand and will respect your decision. Here are the steps to do so:
                    </Text>
                </Box>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 1:
                                </Text>
                                <Text fontSize={3}>
                                   Open the Flop App on your device.
                                </Text>
                            </div>
                            <img
                                src={step1Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 2:
                                </Text>
                                <Text fontSize={3}>
                                    Tap on the 'Account' or 'Profile' icon at the bottom right of the screen.
                                </Text>
                            </div>
                            <img
                                src={step2Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 3:
                                </Text>
                                <Text fontSize={3}>
                                    Go to ‘Settings', then click on 'Account’
                                </Text>
                            </div>
                            <img
                                src={step3Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 4:
                                </Text>
                                <Text fontSize={3}>
                                    Scroll down to find the 'Manage account' option.
                                </Text>
                            </div>
                            <img
                                src={step4Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 5:
                                </Text>
                                <Text fontSize={3}>
                                    Click on the button ‘Delete my account’.
                                </Text>
                            </div>
                            <img
                                src={step5Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                        <Col lg="4" className="mb-5">
                            <div className="d-flex align-items-start" style={{height: 115}}>
                                <Text
                                    style={{whiteSpace: 'nowrap'}}
                                    color="heading"
                                    fontSize={4}
                                    fontWeight={600}
                                    mb={2}
                                    mr={2}
                                >
                                    Step 6:
                                </Text>
                                <Text fontSize={3}>
                                    You will be asked to confirm your decision as this is irreversible. If you're sure, tap 'Confirm.'
                                </Text>
                            </div>
                            <img
                                src={step6Png}
                                alt="delete account step 1"
                                className="w-100 img-fluid"
                            />
                        </Col>
                    </Row>
                </Container>
                <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 5]}>
                    <Text color="dark" mb={4}>
                        Please note that deleting your Flop App account will permanently remove all your account information, including your profile, your game history, and any unused chips or tickets. This action cannot be reversed.
                    </Text>
                    <Text color="dark" mb={4}>
                        We are dedicated to protecting our users' privacy and personal information. All the data associated with your account will be deleted in accordance with our Privacy Policy and Google Play's policies.
                    </Text>
                    <Text color="dark" mb={4}>
                        If you have any questions about this process or if you encounter any issues, please feel free to reach out through our provided contact form. Our team is always here to assist you.
                    </Text>
                    <Text color="dark" mb={4}>
                        Thank you for your understanding and for giving Flop App a try. We hope to see you at the poker tables again in the future!
                    </Text>
                    <Text color="dark">
                        Flop App Team
                    </Text>
                </Box>
            </Section>
        </>
    );
};

export default Body;
